<script lang="ts" setup>
import { TransitionPresets } from '@vueuse/core'

const { startAnimation } = defineProps<{
  startAnimation: boolean
}>()

const stats = [
  { id: 1, name: 'klientů', value: 1027, icon: 'carbon:user' },
  { id: 2, name: 'let praxe', value: 40, icon: 'carbon:time' },
  { id: 3, name: 'kauz', value: 2394, icon: 'carbon:airport-location' },
  { id: 3, name: 'úspěšnost', value: 98, icon: 'carbon:checkmark-outline' },
]

const statNumbers = ref([0, 0, 0, 0])
const transition = useTransition(statNumbers, {
  duration: 2500,
  transition: TransitionPresets.linear,
})

watch(() => startAnimation, (value) => {
  if (value) {
    statNumbers.value = stats.map(stat => stat.value)
  }
}, { immediate: true })
</script>

<template>
  <div class="grid grid-cols-1 items-start xl:grid-cols-7">
    <div class="pb-20 pr-0 pt-8 xl:order-2 xl:col-span-4 xl:p-24 xl:pr-0 xl:pt-10">
      <h2
        class="h2 animate-fade-in mb-4 xl:mb-6"
        :class="{
          'animate-fade-in': startAnimation,
        }"
      >
        Advokátní kancelář s tradicí
      </h2>

      <p
        class="mb-8 !leading-relaxed text-black/70 xl:mb-4 xl:max-w-[50ch]"
        :class="{
          'animate-fade-in animation-delay-300': startAnimation,
        }"
      >
        Naše kancelář sídlí na Karlově náměstí v Praze. Specializujeme na poskytování komplexních právních služeb, jako je občanské právo, obchodní právo, rodinné právo a dalším oblastem. Našim klientům nabízíme osobní přístup a maximální preciznost.
      </p>

      <p
        class="mb-8 !leading-relaxed text-black/70 xl:mb-4 xl:max-w-[50ch]"
        :class="{
          'animate-fade-in animation-delay-600': startAnimation,
        }"
      >
        S více než 40 lety zkušeností v oblasti práva jsme partnerem, na kterého se můžete spolehnout. Naše odborné služby zahrnují mimo jiné poradenství, zastupování před soudy a sjednávání smluv.
      </p>

      <div
        class="mb-16 mt-12"
        :class="{
          'animate-fade-in animation-delay-900': startAnimation,
        }"
      >
        <AppButton
          as-child
          class="border-2 border-secondary text-secondary hover:bg-secondary/5 hover:text-secondary/90"
          size="xl"
          variant="outline"
        >
          <NuxtLink to="/o-nas">
            Více o nás
          </NuxtLink>
        </AppButton>
      </div>

      <div
        v-if="false"
        :class="{ 'animate-fade-in animation-delay-900': startAnimation }"
      >
        <blockquote class="mb-2 leading-normal text-primary/70">
          <Icon
            class="mr-1"
            name="carbon:quotes"
            size="1.25em"
          />

          <span>Nikdy se nebojte zvednout hlas pro poctivost a pravdu proti nespravedlnosti a lhaní</span>
        </blockquote>

        <p class="text-sm font-medium text-secondary">
          —Miloslav Tutter, <cite>Praha</cite>
        </p>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-4 xl:col-span-3">
      <div
        v-for="stat, index in stats"
        :key="stat.id"
        class="relative flex aspect-square flex-col items-center justify-center gap-4 overflow-hidden bg-primary/10"
        :class="{
          'animate-fade-in': startAnimation,
          'animation-delay-300': index === 1 && startAnimation,
          'animation-delay-600': index === 2 && startAnimation,
          'animation-delay-900': index === 3 && startAnimation,
        }"
      >
        <Icon
          class="absolute right-[-30%] top-[-30%] -z-10 text-primary/[0.032]"
          :name="stat.icon"
          size="17em"
        />

        <div class="font-serif text-4xl font-bold lining-nums text-primary xl:text-6xl">
          {{ transition[index]!.toFixed(0) }}
          <template v-if="index === 3">
            %
          </template>
        </div>

        <div class="text-xs uppercase tracking-wider text-black/50">
          {{ stat.name }}
        </div>
      </div>
    </div>
  </div>
</template>
