<script setup lang="ts">
const services = [
  {
    title: 'Historie a hodnoty',
    description: 'Naše advokátní kancelář byla založena v roce 1991 JUDr. Jitkou Tutterovou. Dbáme na osobní vztah s každým klientem a poskytujeme služby srovnatelné s velkými advokátními kancelářemi.',
  },
  {
    title: 'Tým expertů',
    description: 'Spolupracujeme s daňovými experty, znalci a zahraničními advokáty. Naše dlouholeté zkušenosti nám umožňují poskytovat konkrétní rady s jasnými výsledky.',
  },
  {
    title: 'Záruka spolehlivosti',
    description: 'Na nás se můžete spolehnout, vždy stojíme za Vašimi zády. S našimi službami máte jistotu pevného názoru a konkrétního výsledku v jakékoliv záležitosti.',
  },
  {
    title: 'Služby pro každého',
    description: 'Věříme, že každý má nárok na kvalitní právní služby. Nabízíme služby za předem dohodnutou cenu, ať již potřebujete řešit problém z jakékoliv oblasti.',
  },
]
</script>

<template>
  <div>
    <div class="grid grid-cols-1 gap-8 md:grid-cols-2">
      <div v-for="service, key in services" :key>
        <h3 class="h3 mb-4">
          {{ service.title }}
        </h3>

        <p class="text-sm text-muted-foreground">
          {{ service.description }}
        </p>
      </div>
    </div>
  </div>
</template>
