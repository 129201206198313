<script setup lang="ts">
const references = [
  {
    name: 'Právnická osoba',
    category: 'obchodní právo',
    categoryLink: '/pravni-sluzby/obchodni-a-korporatni-pravo',
    text: 'Právnická kancelář nám pomohla úspěšně vyřešit složitý obchodní spor díky profesionálnímu přístupu a odborným znalostem. Průběžně nás informovali o každém kroku, což nám poskytlo pocit jistoty a kontroly nad situací.',
  },
  {
    name: 'Fyzická osoba',
    category: 'nemovitostní právo',
    categoryLink: '/pravni-sluzby/nemovitosti',
    text: 'Spolupráce při koupi nemovitosti proběhla hladce a kancelář zajistila, že veškeré právní záležitosti byly řádně ošetřeny. Rychlá reakce na všechny naše dotazy nás ubezpečila, že jsme v dobrých rukou.',
  },
  {
    name: 'Fyzická osoba',
    category: 'rodinné právo',
    categoryLink: '/pravni-sluzby/rodinne-pravo',
    text: 'Velmi oceňuji empatický a diskrétní přístup během rozvodového řízení, který nám pomohl překonat obtížnou situaci. Celý proces probíhal citlivě a s důrazem na zájmy všech zúčastněných stran.',
  },
  {
    name: 'Právnická osoba',
    category: 'Smluvní právo',
    categoryLink: '/pravni-sluzby/smluvni-pravo-a-smlouvy',
    text: 'Kancelář nám poskytla rychlou a profesionální pomoc při revizi a přípravě smluv, díky čemuž jsme uzavřeli důležité dohody bez problémů. Jejich preciznost a pozornost k detailům nám ušetřily mnoho starostí.',
  },
  {
    name: 'Fyzická osoba',
    category: 'pracovní právo',
    categoryLink: '/pravni-sluzby/pracovni-pravo',
    text: 'V případě pracovního sporu kancelář zajistila efektivní a spravedlivé řešení, což vedlo k úspěšnému výsledku v náš prospěch. Ocenili jsme zejména jejich jasné právní rady a osobní přístup k našemu případu.',
  },
  {
    name: 'Fyzická osoba',
    category: 'trestní právo',
    categoryLink: '/pravni-sluzby/trestni-pravo',
    text: 'Díky odborné pomoci AK Tutter jsme úspěšně zvládli složitý trestní případ. Jejich důkladná příprava a profesionální přístup nám poskytly potřebnou podporu a jistotu během celého procesu.',
  },
]

const marqueeRef = ref<HTMLDivElement | null>(null)
const isPaused = ref(false)

function pauseMarquee() {
  isPaused.value = true
}

function resumeMarquee() {
  isPaused.value = false
}

onMounted(() => {
  if (marqueeRef.value) {
    marqueeRef.value.style.animationPlayState = 'running'
  }
})

onUnmounted(() => {
  if (marqueeRef.value) {
    marqueeRef.value.style.animationPlayState = 'paused'
  }
})
</script>

<template>
  <div class="marquee-wrapper relative overflow-hidden">
    <div
      class="marquee-container whitespace-nowrap"
      @mouseenter="pauseMarquee"
      @mouseleave="resumeMarquee"
    >
      <div
        ref="marqueeRef"
        class="marquee-content inline-block"
        :class="{ paused: isPaused }"
      >
        <div
          v-for="(reference, index) in [...references, ...references]"
          :key="`${index}-${reference.name}`"
          class="mx-8 inline-block w-80 align-top"
        >
          <div class="flex items-start gap-4">
            <div class="min-w-0 flex-1 space-y-1.5">
              <p class="mb-4 truncate font-serif text-lg">
                {{ reference.name }}
              </p>

              <NuxtLink
                class="truncate text-xs font-medium uppercase tracking-wider text-primary/60"
                prefetch-on="interaction"
                :to="reference.categoryLink"
              >
                {{ reference.category }}
              </NuxtLink>

              <blockquote class="whitespace-normal text-sm text-muted-foreground">
                <Icon class="mr-1 inline-block shrink-0" name="carbon:quotes" />

                <span>{{ reference.text }}</span>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pointer-events-none absolute inset-y-0 left-0 w-12 bg-gradient-to-r from-white to-transparent xl:w-48" />

    <div class="pointer-events-none absolute inset-y-0 right-0 w-12 bg-gradient-to-l from-white to-transparent xl:w-48" />
  </div>
</template>

<style scoped>
.marquee-wrapper {
  width: 100%;
}

.marquee-container {
  width: 100%;
}

.marquee-content {
  animation: marquee 60s linear infinite;
}

.marquee-content.paused {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
</style>
