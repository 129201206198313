<script setup lang="ts">
import type { Hero } from '~/components/content/Heros/types'

const heros = shallowRef<Hero[]>([
  { title: 'Jsme komplexní', description: 'V advokacii pracujeme téměr ve všech oblastech práva a máme za sebou nespočet úspěšných soudních sporů či jiných forem zastupování či mediace.' },
  { title: 'Jsme zásadoví', description: 'Zakládáme si na poctivém přístupu ke každému klientovi, kterému vždy poskytujeme právní služby na nejvyšší úrovni.' },
  { title: 'Jsme upřímní', description: 'Každému klientovi vždy sdělujeme pravděpodobný výsledek věci. Od klientů nepožadujeme zbytečně vynaložené prostředky v předem prohraném sporu.' },
])
</script>

<template>
  <div class="-mt-20 xl:mt-0">
    <div class="relative h-[620px] text-white xl:h-screen xl:min-h-[750px]">
      <NuxtImg
        alt="Header background"
        class="absolute inset-0 -z-10 h-full w-screen object-cover"
        fit="cover"
        format="avif,webp"
        height="1080"
        :preload="{ fetchPriority: 'high' }"
        quality="90"
        src="/images/homepage-background.png"
        width="1920"
      />

      <div class="container mt-20 flex h-full flex-col items-start pt-16 xl:mt-0 xl:justify-center xl:pt-36">
        <h1 class="animate-fade-in animation-delay-300 h1 mb-8 text-[2.5rem] text-white xl:mb-10 xl:text-7xl">
          Naše zkušenosti jsou<br class="hidden md:block"> klíčem k&nbsp;Vašemu úspěchu
        </h1>

        <p class="animate-fade-in animation-delay-600 mb-12 text-lg !leading-relaxed text-white/50 xl:mb-16 xl:max-w-[50ch] xl:text-xl">
          Přidejte se k více než 1000 spokojeným klientům, kteří důvěřují našim odborným právním službám. S námi získáte partnera, který porozumí Vašim potřebám a podpoří Vás na cestě k Vašim cílům.
        </p>

        <AppButton
          as-child
          class="animate-fade-in animation-delay-900"
          size="xl"
          variant="secondary"
        >
          <NuxtLink to="#contact">
            <Icon name="carbon:send-alt" size="1.5em" />

            <span class="ml-2">Napište nám</span>
          </NuxtLink>
        </AppButton>
      </div>
    </div>

    <div class="space-y-20 pt-8 xl:space-y-32 xl:pt-32">
      <AppSection v-slot="{ defaultSlotIsVisible }">
        <Stats :start-animation="defaultSlotIsVisible" />
      </AppSection>

      <AppSection
        background="primary"
        title="Proč jít do spolupráce s námi?"
      >
        <Heros :heros />
      </AppSection>

      <AppSection
        description="Zakládáme si na povinnosti mlčenlivosti a respektujeme soukromí klientů ve všech právních záležitostech. Reference jsou proto anonymizovány a nezveřejňujeme detaily případů, abychom zajistili maximální ochranu osobních údajů a diskrétnost."
        no-container
        title="Reference našich klientů"
      >
        <References />
      </AppSection>

      <AppSection
        class="px-0 xl:!mt-44 xl:px-8"
        image="/images/legal-services.webp"
        title="Profesionální služby"
      >
        <LegalServicesInfo />
      </AppSection>

      <AppSection
        background="primary"
        class="xl:pb-36"
        description="Advokátní kancelář Tutter nabízí široké spektrum právních služeb v různých oblastech práva. Každý klient je pro nás jedinečný, a proto přistupujeme k jeho potřebám individuálně, abychom mu poskytli efektivní řešení. Poskytujeme profesionální poradenství a právní zastupování pro jednotlivce i firmy."
        title="Právní služby"
      >
        <LegalSpecialization />
      </AppSection>
    </div>
  </div>
</template>
